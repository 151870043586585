import React from 'react';
import CategoryPosts from '../components/Blog/CategoryPosts';
import { graphql } from 'gatsby';

const BlogCategoryPage = (props) => {
  return <CategoryPosts propsData={props} />;
};

export default BlogCategoryPage;

export const categoryPostsQuery = graphql`
  query categoryPostsQuery(
    $skip: Int!
    $limit: Int!
    $category: String!
    $siteId: Int!
  ) {
    allCraftBlogBlogEntry(
      limit: $limit
      skip: $skip
      sort: { fields: postDate, order: DESC }
      filter: {
        category: { elemMatch: { slug: { eq: $category } } }
        siteId: { eq: $siteId }
      }
    ) {
      nodes {
        id
        title
        slug
        author {
          name
          remoteId
        }
        postDate
        dateUpdated
        metaDescription
        featuredImage {
          id
          url
          title
          ... on Craft_imagesEn_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  aspectRatio: 3.68
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          ... on Craft_imagesRu_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  aspectRatio: 3.68
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
        featuredImageRetina {
          id
          url
          title
          ... on Craft_imagesEn_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  aspectRatio: 3.68
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          ... on Craft_imagesRu_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  aspectRatio: 3.68
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
        category {
          title
          id
          slug
        }
      }
    }
  }
`;
