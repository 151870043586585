import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Pager from './Pager';
import PostItem from './PostItem';
import useDateFormat from '../../hooks/useDateFormat';
import useSiteMetadata from '../../hooks/useSiteMetadata';
import { usePageContext } from '../../i18n/PageContext';
import { getLocalizedPath } from '../../i18n/Link';

const IndexPostContent = ({ nodes, currentPage, numPages, type }) => {
  const format = useDateFormat();
  const { url } = useSiteMetadata();
  const { i18n } = usePageContext();
  const { language } = i18n;

  const logoUrl = `${url}/blog-posting-logo.png`;

  return (
    <div style={{ minHeight: 1505 }}>
      <Row
        className="justify-content-center"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        {nodes.map((item) => {
          const pageUrl = getLocalizedPath(language, `/blog/${item.slug}/`);
          const authorPath = getLocalizedPath(
            language,
            `/author/${item.author.remoteId}/`
          );
          const postDate = format(new Date(item.postDate), 'yyyy-dd-MM');
          const dateUpdated = format(new Date(item.dateUpdated), 'yyyy-dd-MM');
          const image =
            item.featuredImageRetina?.[0] || item.featuredImage?.[0];
          const imageOriginalUrl =
            image?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback
              ?.src;

          return (
            <Col md="6" lg="4" key={item.slug} className="mb-5">
              <PostItem image={image} {...item} />
              {type === 'blog' && (
                <div
                  itemProp="blogPosts"
                  itemScope={true}
                  itemType="https://schema.org/BlogPosting"
                >
                  <meta itemProp="datePublished" content={postDate} />
                  <meta itemProp="dateModified" content={dateUpdated} />
                  <meta itemProp="headline" content={item.title} />
                  <meta itemProp="description" content={item.metaDescription} />
                  {imageOriginalUrl && (
                    <meta itemProp="image" content={imageOriginalUrl} />
                  )}

                  <meta
                    itemScope
                    itemProp="mainEntityOfPage"
                    itemType="https://schema.org/WebPage"
                    itemID={`${url}${pageUrl}`}
                  />

                  <div
                    itemProp="author"
                    itemScope={true}
                    itemType="https://schema.org/Person"
                  >
                    <meta itemProp="name" content={item.author.name} />
                    <link itemProp="url" href={`${url}${authorPath}`} />
                  </div>

                  <div
                    itemProp="publisher"
                    itemScope={true}
                    itemType="https://schema.org/Organization"
                  >
                    <meta itemProp="name" content="SpySERP" />
                    <meta
                      itemProp="address"
                      content="27 Old Gloucester Street London WC1N 3AX United Kingdom"
                    />
                    <link itemProp="url" href="https://spyserp.com/" />
                    <div
                      itemProp="logo"
                      itemScope={true}
                      itemType="https://schema.org/ImageObject"
                    >
                      <meta itemProp="image" content={logoUrl} />
                      <meta itemProp="width" content={550} />
                      <meta itemProp="height" content={60} />
                    </div>
                  </div>
                </div>
              )}
            </Col>
          );
        })}
      </Row>

      <Row className="justify-content-center">
        <div className="col-xl-6 col-lg-7 col-md-9">
          <div className="text-center">
            <Pager currentPage={currentPage} numPages={numPages} type={type} />
          </div>
        </div>
      </Row>
    </div>
  );
};

export default IndexPostContent;
