import React from 'react';
import { Container, Row } from 'react-bootstrap';
import CategoryWidget from './CategoryWidget';

import './BlogIndex.css';
import IndexPostContent from './IndexPostContent';
import MetaTags from '../Seo/MetaTags';
import { stripHtmlTags } from '../../../utils';

const CategoryPosts = ({
  propsData: {
    data: { allCraftBlogBlogEntry },
    pageContext,
  },
}) => {
  const { currentPage, numPages, categoryData, categories, category } =
    pageContext;
  const { nodes } = allCraftBlogBlogEntry;

  return (
    <>
      <MetaTags
        title={
          categoryData.metaTitle ? categoryData.metaTitle : categoryData.title
        }
        description={
          categoryData.metaDescription ||
          stripHtmlTags(categoryData.shortDescription)
        }
        keywords={categoryData.metaKeywords}
      />

      <div className="news-section pt-12 pb-8 py-lg-23 bg-default-2">
        <Container>
          <Row className="justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-9 mt-9">
              <div className="section-title text-center mb-11 mb-lg-18">
                <h1 className="title gr-text-4 mb-6">{categoryData.title}</h1>
              </div>
            </div>
          </Row>

          <CategoryWidget
            categories={categories}
            usedCategories={[{ slug: category }]}
            disableSearch
          />

          <IndexPostContent
            nodes={nodes}
            currentPage={currentPage}
            numPages={numPages}
            type={category}
          />
        </Container>
      </div>
    </>
  );
};

export default CategoryPosts;
