import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '../../i18n/Link';

const Pager = (props) => {
  const { currentPage, numPages, type } = props;
  const { t } = useTranslation('Blog');

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? '' : '/p' + (currentPage - 1).toString() + '/';
  const nextPage = '/p' + (currentPage + 1).toString() + '/';

  if (numPages <= 1) {
    return null;
  }

  const linksNum = 9;
  const linkCenter = Math.ceil(linksNum / 2);
  let startFrom = 1;
  if (currentPage > linkCenter) {
    startFrom = currentPage - linkCenter + 1;
  }

  const pagesArray = [];
  let pagesDisplayedCount = linksNum + startFrom;
  if (pagesDisplayedCount > numPages) {
    pagesDisplayedCount = numPages;
  }

  for (let i = startFrom; i <= pagesDisplayedCount; i++) {
    pagesArray.push(i);
  }

  return (
    <nav aria-label="Pager">
      <ul className="pagination justify-content-center">
        {!isFirst && (
          <li className="page-item">
            <Link
              title={t('link.prevPageTitle')}
              to={`/${type}${prevPage}`}
              rel="prev"
              className="page-link"
            >
              <span aria-hidden="true">&laquo;</span>
            </Link>
          </li>
        )}

        {Array.from(pagesArray, (i) => (
          <li
            key={`pagination-number${i}`}
            className={'page-item ' + (i === currentPage ? 'active' : '')}
          >
            <Link
              title={t('link.pageNumberTitle', { pageNumber: i })}
              className="page-link"
              to={`/${type}${i === 1 ? '' : '/p' + i}/`}
            >
              {i}
            </Link>
          </li>
        ))}

        {!isLast && (
          <li className="page-item">
            <Link
              title={t('link.nextPageTitle')}
              to={`/${type}${nextPage}`}
              rel="next"
              className="page-link"
            >
              <span aria-hidden="true">&raquo;</span>
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pager;
