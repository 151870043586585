import React, { useContext, useLayoutEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../context/GlobalContext';
import Link, { getLocalizedPath } from '../../i18n/Link';
import './BlogIndex.css';
import SearchIcon from '../../assets/icons/font-awesome/solid/search.svg';
import TimesIcon from '../../assets/icons/font-awesome/solid/times.svg';

const CategoryWidget = ({
  categories,
  usedCategories = false,
  disableSearch = false,
  showOnlyUsedCategories = false,
}) => {
  const gContext = useContext(GlobalContext);
  const { i18n } = useTranslation();
  const { language } = i18n;
  const searchInputRef = useRef(null);
  let categoriesList;

  function goToBlogIndex() {
    const link = getLocalizedPath(language, '/blog/');
    return navigate(link);
  }

  if (showOnlyUsedCategories) {
    categoriesList = categories.filter((category) =>
      usedCategories.some((usedCategory) => usedCategory.slug === category.slug)
    );
  } else {
    categoriesList = categories;
  }

  const clickSearch = () => {
    if (gContext.blogSearchActive) {
      // Make search
      makeSearch(gContext.blogSearchActive);
    } else {
      gContext.setBlogSearchActive(true);
    }
  };

  const offSearch = () => {
    gContext.setBlogSearchActive(false);
    gContext.setBlogSearchQuery('');
    goToBlogIndex();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      makeSearch(e.target.value);
    } else {
      gContext.setBlogSearchQuery(e.target.value);
      goToBlogIndex();
    }
  };

  const makeSearch = (value) => {
    gContext.setBlogSearchQuery(value);
  };

  useLayoutEffect(() => {
    if (gContext.blogSearchActive && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [gContext.blogSearchActive]);

  return (
    <div className="pb-10 pt-5">
      {(!gContext.blogSearchActive || disableSearch) && (
        <div className="d-flex align-items-center flex-nowrap w-100 pt-2 mb-1">
          <div className="flex-fill">
            {categoriesList.map(({ id, title, slug }) => {
              let className =
                'badge p-4 mr-5 mb-5 text-primary gr-text-15 gr-bg-blue-opacity-1 rounded-pill text-uppercase';
              if (usedCategories) {
                usedCategories.forEach(({ slug: usedSlug }) => {
                  if (usedSlug === slug) {
                    className += ' bg-primary text-white';
                  }
                });
              }

              return (
                <Link to={`/${slug}/`} className={className} key={id}>
                  {title}
                </Link>
              );
            })}
          </div>

          {!disableSearch && (
            <span
              onClick={clickSearch}
              className="badge circle-40 gr-bg-blue-opacity-1"
            >
              <span className="svg-color-primary">
                <SearchIcon width={16} />
              </span>
            </span>
          )}
        </div>
      )}

      {gContext.blogSearchActive && !disableSearch && (
        <div className="d-flex align-items-center flex-nowrap w-100">
          <form action="/" className="flex-fill pr-5">
            <div className="form-group mb-0">
              <input
                ref={searchInputRef}
                onKeyDown={handleKeyDown}
                onChange={handleKeyDown}
                className="form-control gr-text-11 px-7 border"
                type="text"
                id="keyword"
                placeholder="Job title or keyword"
                value={gContext.blogSearchQuery ? gContext.blogSearchQuery : ''}
              />
            </div>
          </form>

          <div>
            <span
              onClick={offSearch}
              className="badge circle-40 gr-bg-blue-opacity-1"
            >
              <span className="svg-color-primary">
                <TimesIcon width={12} />
              </span>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryWidget;
